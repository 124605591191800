<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2 no-gutters">
		<div
			v-if="!paymentMethodsLoading"
			class="col">
			<div class="row">
				<div class="col-12">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item border-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="selectedPaymentMethodName = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<li
						:class="CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName) ? 'border-0' : 'border-left-0 border-right-0 border-top-0'"
						class="list-group-item rounded-0 pb-0">
						<template v-if="CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('cc_information') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="px-3">
								<div
									:class="{'px-0': !['xs'].includes(windowWidth) && (!addCreditCard && creditCardsData.length) }"
									class="col-12 address-list">
									<credit-card-grid
										v-if="!addCreditCard && creditCardsData.length && enabledPaymentMethods[selectedPaymentMethodName]"
										:loading="creditCardsLoading"
										:data="creditCardsData"
										:show-select="true"
										:show-default="false"
										:show-disclaimer="false"
										:selected="Number(cardId)"
										@change="cardId = $event" />
									<div
										v-if="!addCreditCard && creditCardsData.length && !creditCardsLoading && enabledPaymentMethods[selectedPaymentMethodName]"
										class="row">
										<div class="col-12">
											<hr class="mt-0">
										</div>
									</div>
									<template v-if="(addCreditCard || !creditCardsData.length) && !creditCardsLoading && enabledPaymentMethods[selectedPaymentMethodName]">
										<div class="row mb-2">
											<div
												:class="{'px-0': !['xs'].includes(windowWidth) }"
												class="col-12">
												<template v-for="(item, index) in displayedAllowedCC">
													<img
														:key="index"
														:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
														align="right"
														class="text-right ml-2 resize-img">
												</template>
											</div>
										</div>
										<new-credit-card
											ref="newCreditCardView"
											:borderless="true"
											:show-buttons="false"
											:include-title="false"
											:show-disclaimer="true"
											:show-default-switch="false"
											new-address-btn-variant="link"
											new-address-btn-class="pl-2 py-0"
											no-body
											@cardCreated="cardCreated">
											<template
												v-if="buttonStates.seeCreditCardList === 'enabled'"
												v-slot:middle>
												<button
													:class="{ 'w-100' : ['xs', 'sm'].includes(windowWidth) }"
													class="btn btn-link b-4 float-left px-2 pt-0 pb-4"
													@click="cardFormState">
													{{ translate('see_credit_card_list') }}
												</button>
											</template>
										</new-credit-card>
									</template>
									<b-alert
										:show="!enabledPaymentMethods[selectedPaymentMethodName]"
										variant="danger"
										v-html="translate('creditcard_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											max: (typeof paymentMethodConditions[selectedPaymentMethodName] !== 'undefined') ? paymentMethodConditions[selectedPaymentMethodName].max : '',
											min: (typeof paymentMethodConditions[selectedPaymentMethodName] !== 'undefined') ? paymentMethodConditions[selectedPaymentMethodName].min : '',
										})" />
								</div>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'checkmo'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('cash_payment') }}
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-alert
										:show="!enabledPaymentMethods['checkmo']"
										variant="danger"
										v-html="translate('cash_payments_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											min: (typeof paymentMethodConditions['checkmo'] !== 'undefined') ? paymentMethodConditions['checkmo'].min : '',
										})" />
									<template v-if="enabledPaymentMethods['checkmo']">
										<b-alert
											show
											variant="warning"
											class="h5">
											<b>{{ translate('important_note').toUpperCase() }}</b>: {{ translate('international_wires_only') }}
										</b-alert>
										<b-alert
											show
											variant="info"
											class="text-normal"
											v-html="translate(`cash_payments_details${cashNoDayLimitcountries.includes(country) ? '_no_day_limit' : ''}`, { displayedLink: companyEmail, supportLink: companyEmail })" />
										<b-alert
											show
											variant="warning">
											{{ translate('cash_payments_disclaimer') }}
										</b-alert>
									</template>
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'cashondelivery'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('wallet_information') }}
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<div
										v-if="!walletBalanceLoading && balance.hasOwnProperty(walletType)"
										class="h4 text-right mb-0">
										{{ translate('current_balance') }}: {{ balance[walletType] }}
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<input-text
										id="wallet_password"
										name="wallet_password"
										:required="true"
										:label="translate('wallet_password')"
										:errors="validationErrors[`${stepValidation}.payment_method.wallet_password`]"
										:setter-value="walletPassword"
										type="password"
										@dataChanged="walletPassword = $event" />
								</b-col>
							</b-row>
							<b-row
								v-if="(typeof validationErrors[`${stepValidation}.payment_method.wallet_type`] !== 'undefined')">
								<b-col
									:id="`${stepValidation}.payment_method.wallet_type`">
									<b-alert
										variant="danger"
										show>
										<div
											v-for="error in validationErrors[`${stepValidation}.payment_method.wallet_type`]"
											:key="error">
											<span
												v-text="error" />
											<br>
										</div>
									</b-alert>
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'banktransfer'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('banktransfer') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="row">
								<b-col>
									<b-alert
										show
										variant="info"
										v-html="translate('banktransfer_details')" />
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										show
										variant="warning">
										{{ translate('banktransfer_disclaimer') }}
									</b-alert>
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'sezzle'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('sezzle') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										:show="!enabledPaymentMethods['sezzle']"
										variant="danger"
										v-html="translate('sezzle_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											min: (typeof paymentMethodConditions['sezzle'] !== 'undefined') ? paymentMethodConditions['sezzle'].min : '',
											max: (typeof paymentMethodConditions['sezzle'] !== 'undefined') ? paymentMethodConditions['sezzle'].max : ''
										})" />
									<b-alert
										v-if="enabledPaymentMethods['sezzle']"
										show
										variant="info"
										v-html="translate('sezzle_details_purchase', { faqUrl: sezzleFaq })" />
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'oxxopay'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('oxxopay') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										:show="!enabledPaymentMethods['oxxopay']"
										variant="danger"
										v-html="translate('oxxopay_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											max: (typeof paymentMethodConditions['oxxopay'] !== 'undefined') ? paymentMethodConditions['oxxopay'].max : ''
										})" />
									<div v-if="enabledPaymentMethods['oxxopay']">
										<b-alert
											show
											variant="info"
											v-html="translate('oxxopay_details')" />
										<b-alert
											show
											variant="warning"
											v-html="translate('oxxopay_details_place_holder', { days: limitTransferDays.oxxopay })" />
									</div>
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'gcash'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('gcash') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										:show="!enabledPaymentMethods['gcash']"
										variant="danger"
										v-html="translate('gcash_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											min: (typeof paymentMethodConditions['gcash'] !== 'undefined') ? paymentMethodConditions['gcash'].min : '',
											max: (typeof paymentMethodConditions['gcash'] !== 'undefined') ? paymentMethodConditions['gcash'].max : ''
										})" />
									<div v-if="enabledPaymentMethods['gcash']">
										<b-alert
											show
											variant="info"
											v-html="translate('gcash_details')" />
										<b-alert
											show
											variant="warning"
											v-html="translate('gcash_details_place_holder', { hours: limitTransferDays.gcash * 24 })" />
									</div>
								</b-col>
							</b-row>
						</template>
						<template v-if="selectedPaymentMethodName === 'lptpaypal'">
							<b-row
								v-if="paymentMethods.length === 1">
								<b-col>
									<div class="h4 my-2">
										{{ translate('lptpaypal') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										:show="!enabledPaymentMethods['lptpaypal']"
										variant="danger"
										v-html="translate('sezzle_unavailable', {
											url: $router.resolve({ name: 'PSStore' }).href,
											max: (typeof paymentMethodConditions['lptpaypal'] !== 'undefined') ? paymentMethodConditions['lptpaypal'].max : ''
										})" />
									<b-alert
										v-if="enabledPaymentMethods['lptpaypal']"
										show
										variant="info"
										v-html="translate('lptpaypal_details_purchase')" />
								</b-col>
							</b-row>
						</template>
					</li>
				</div>
			</div>
			<template v-if="!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)">
				<div class="row">
					<div class="col-12">
						<li class="list-group-item border-0 rounded-0 pt-0">
							<div class="row mt-2">
								<div class="col-12">
									<div class="h4 mb-0">
										{{ translate('billing_address') }}
									</div>
								</div>
							</div>
						</li>
					</div>
				</div>
				<template>
					<div class="px-2 pb-1">
						<div class="row px-1">
							<div class="col-12">
								<address-radio-button
									class="mw-100"
									:address-data="addressData"
									:address-loading="addressLoading"
									:form-import="form"
									:hide-new-address="addAddress"
									:display-add-new-address="false"
									:borderless="true"
									button-group-name="billing-address"
									@add-address="addressFormState" />
								<div
									v-if="!addAddress && !addressLoading"
									class="row">
									<div class="col-12 px-4">
										<hr class="mt-0">
									</div>
								</div>
								<div
									v-if="addAddress && !addressLoading"
									class="pt-3 px-2">
									<address-country-form
										:user-country="true"
										:form-import="form"
										:errors-import="addressErrors.errors"
										:prefix-errors="''"
										:display-cancel-button="false"
										:display-submit-button="false"
										:display-goback-button="false"
										:display-override-toggle="showOverrideValidation" />
								</div>
							</div>
						</div>
						<div
							v-if="!addressLoading"
							class="row px-2">
							<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
								<button
									v-if="buttonStates.seeAddressList === 'enabled'"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('see_address_list') }}
								</button>
								<button
									v-if="buttonStates.useAnotherAddress === 'enabled'"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('use_another_address') }}
								</button>
							</div>
						</div>
						<div
							v-if="buttonStates.newAddressCancel !== 'hidden' || buttonStates.newAddressSave !== 'hidden'"
							class="row p-2">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
								class="col-12 col-md-auto">
								<div class="row no-gutters justify-content-end">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
										class="col order-2 order-md-1">
										<b-button
											v-if="buttonStates.newAddressCancel !== 'hidden'"
											:disabled="buttonStates.newAddressCancel === 'disabled'"
											style="min-width: 150px"
											class="w-100"
											variant="light"
											size="lg"
											@click="addressFormState; $emit('cancel')">
											{{ translate('cancel') }}
										</b-button>
									</div>
									<div
										:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
										class="col col-md-6 order-1 order-md-2">
										<b-button
											v-if="buttonStates.newAddressSave !== 'hidden'"
											:disabled="buttonStates.newAddressSave === 'disabled'"
											class="w-100"
											style="min-width: 200px"
											variant="primary"
											size="lg"
											@click="newAddress(form)">
											{{ translate('continue') }}
										</b-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</template>
			<div
				v-if="buttonStates.useAnotherCreditCard === 'enabled' && enabledPaymentMethods[selectedPaymentMethodName]"
				class="row px-3">
				<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
					<button
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
						class="btn btn-link b-4 float-left pt-0"
						@click="cardFormState">
						{{ translate('use_another_credit_card') }}
					</button>
				</div>
			</div>
			<div
				v-if="buttonStates.mainCancel !== 'hidden' || buttonStates.mainSave !== 'hidden' || buttonStates.newCreditCardSave !== 'hidden'"
				class="row px-3 pb-1">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
					class="col-12 col-md-auto">
					<div
						v-if="(typeof validationErrors[`${stepValidation}.billing.address_id`] !== 'undefined')"
						class="row p-2">
						<div
							id="address"
							class="col-12">
							<b-alert
								variant="danger"
								show>
								<div
									v-for="error in validationErrors[`${stepValidation}.billing.address_id`]"
									:key="error">
									<span
										v-text="error" />
									<br>
								</div>
							</b-alert>
						</div>
					</div>
					<div class="row no-gutters justify-content-end">
						<div
							v-if="buttonStates.mainCancel !== 'hidden'"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
							class="col order-2 order-md-1">
							<b-button
								:disabled="validating || !!preparing || buttonStates.mainCancel === 'disabled'"
								style="min-width: 150px"
								class="w-100"
								variant="light"
								size="lg"
								@click="$emit('cancel')">
								{{ translate('cancel') }}
							</b-button>
						</div>
						<div
							v-if="buttonStates.mainSave !== 'hidden'"
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							class="col col-md-6 order-1 order-md-2">
							<b-button
								:disabled="validating || !!preparing || buttonStates.mainSave === 'disabled' || !enabledPaymentMethods[selectedPaymentMethodName]"
								style="min-width: 200px"
								class="w-100"
								variant="primary"
								size="lg"
								@click="prepareForStorage()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
						<div
							v-if="buttonStates.newCreditCardSave !== 'hidden'"
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							class="col col-md-6 order-1 order-md-2">
							<b-button
								:disabled="validating || !!preparing || buttonStates.newCreditCardSave === 'disabled' || !enabledPaymentMethods[selectedPaymentMethodName]"
								style="min-width: 200px"
								class="w-100"
								variant="primary"
								size="lg"
								@click="newCreditCard()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="paymentMethodsLoading"
			class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	CREDIT_CARD_FORM_METHODS, ALLOWEDCC_BY_COUNTRY,
} from '@/settings/CreditCard';
import {
	CC_PAYMENT_METHOD_BY_COUNTRY,
	LIMIT_GCASH_TRANSFER_DAYS,
	LIMIT_OXXO_TRANSFER_DAYS,
	SEZZLE_FAQ_URL,
} from '@/settings/Purchase';
import { NO_DAY_LIMIT_CASH_PAYMENT } from '@/settings/Country';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import InputText from '@/components/InputText';
import AddressBook from '@/util/PhysicalStore/AddressBook';
import Country from '@/util/Country';
import Cart from '@/util/PhysicalStore/Cart';
import CreditCard from '@/util/PhysicalStore/CreditCard';
import Wallets from '@/util/Wallets';
import CreditCardGrid from '@/views/Profile/CreditCard/components/CreditCardGrid.vue';
import NewCreditCard from '@/views/PhysicalStore/Profile/CreditCard/components/NewCreditCard.vue';
import CommonMix from '../../mixins/Common';
import Addresses from '../../mixins/Addresses';
import Steps from '../../mixins/Steps';
import store from '../../store';

export default {
	name: 'PaymentEdit',
	components: {
		AddressRadioButton,
		AddressCountryForm,
		CreditCardGrid,
		NewCreditCard,
		InputText,
	},
	mixins: [Addresses, CommonMix, Steps, WindowSizes],
	props: {
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			createAddress: new AddressBook(),
			state: new Country(),
			countryIso: new Country(),
			paymentMethodData: new Cart(),
			creditCards: new CreditCard(),
			creditCard: new CreditCard(),
			wallets: new Wallets(),
			identityId: null,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: 0,
			},
			paymentForm: {},
			alert: new this.$Alert(),
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			purchaseCreditCardsErrors: {},
			walletTypes: WALLET_TYPES,
			billingAddressId: 0,
			cardId: 0,
			selectedPaymentMethodName: 0,
			walletPassword: '',
			walletType: WALLET_TYPES.commission,
			cashNoDayLimitcountries: NO_DAY_LIMIT_CASH_PAYMENT,

			addCreditCard: false,
			addAddress: false,
			showOverrideValidation: false,
			showMainCancel: true,
			sezzleFaq: SEZZLE_FAQ_URL,
			limitTransferDays: {
				oxxopay: LIMIT_OXXO_TRANSFER_DAYS,
				gcash: LIMIT_GCASH_TRANSFER_DAYS,
			},
			CREDIT_CARD_FORM_METHODS,
		};
	},
	computed: {
		paymentMethods() {
			try {
				return this.paymentMethodData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.total_balance;
			} catch (error) {
				return [];
			}
		},
		paymentMethodsLoading() {
			return !!this.paymentMethodData.data.loading;
		},
		walletBalanceLoading() {
			return !!this.wallets.data.loading;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		stepValidation() {
			return this.getStepValidationByName('PurchasePayment');
		},
		creditCardsLoading() {
			return !!this.creditCards.data.loading;
		},
		creditCardsErrors() {
			return this.creditCards.data.errors;
		},
		creditCardsData() {
			try {
				const { data } = this.creditCards.data.response.data;
				if (data === undefined) {
					return [];
				}
				return data;
			} catch (error) {
				return [];
			}
		},
		hasCreditCardsData() {
			const response = this.creditCardsData.length;
			return !!response;
		},
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
		buttonStates() {
			const initialInfoIsComplete = !!this.initialInformation.credit_card.card || !Array.isArray(this.initialInformation.credit_card.billing_address);
			const storedInfoIsComplete = store.state.completedSteps.includes('PurchasePayment');
			const hasInitialOrStoredData = initialInfoIsComplete || storedInfoIsComplete;

			const {
				// validating,
				// preparing,
				addAddress,
				selectedPaymentMethodName,
				addCreditCard,
				hasAddressData,
				hasCreditCardsData,
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
			} = this;

			const loadingAny = [
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
			].some((loading) => loading);

			const buttonStates = {
				mainSave: 'enabled', // can disable
				mainCancel: 'enabled', // can disable
				newAddressSave: 'enabled', // can disable
				newAddressCancel: 'enabled', // can disable
				newCreditCardSave: 'enabled', // can disable
				seeAddressList: 'enabled',
				useAnotherAddress: 'enabled',
				seeCreditCardList: 'enabled',
				useAnotherCreditCard: 'enabled',
			};

			if (loadingAny) {
				Object.keys(buttonStates).forEach((key) => {
					buttonStates[key] = 'disabled';
				});
			}

			if (!hasInitialOrStoredData) {
				buttonStates.mainCancel = 'hidden';
				buttonStates.newAddressCancel = 'hidden';
			}

			if (!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				buttonStates.seeCreditCardList = 'hidden';
				buttonStates.useAnotherCreditCard = 'hidden';
				buttonStates.newCreditCardSave = 'hidden';

				if (addAddress) {
					buttonStates.mainCancel = 'hidden';
					buttonStates.mainSave = 'hidden';
					buttonStates.useAnotherAddress = 'hidden';
				} else {
					buttonStates.newAddressCancel = 'hidden';
					buttonStates.newAddressSave = 'hidden';
					buttonStates.seeAddressList = 'hidden';
				}

				if (!hasAddressData) {
					buttonStates.seeAddressList = 'hidden';
					buttonStates.newAddressCancel = 'hidden';
				}
			}

			if (CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				buttonStates.newAddressCancel = 'hidden';
				buttonStates.newAddressSave = 'hidden';
				buttonStates.seeAddressList = 'hidden';
				buttonStates.useAnotherAddress = 'hidden';

				if (addCreditCard) {
					buttonStates.mainSave = 'hidden';
					buttonStates.useAnotherCreditCard = 'hidden';
				} else {
					buttonStates.newCreditCardSave = 'hidden';
					buttonStates.seeCreditCardList = 'hidden';
				}

				if (!hasCreditCardsData) {
					buttonStates.useAnotherCreditCard = 'hidden';
					buttonStates.seeCreditCardList = 'hidden';
				}
			}
			this.$emit('changePaymentMethodSelected', selectedPaymentMethodName);
			return buttonStates;
		},
		enabledPaymentMethods() {
			return Object.fromEntries(this.paymentMethods.map((paymentMethod) => {
				const codeName = paymentMethod.attributes.method_code_name;
				return [
					codeName,
					this.paymentMethodConditions[codeName]?.isAvailable ?? true,
				];
			}));
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
	},
	watch: {
		'form.address_id': function addressId(newVal) {
			this.billingAddressId = newVal;
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
	},
	async mounted() {
		this.form.country_code = this.country;
		this.getStoredInformation();
		this.initializeFormValues();
		this.initializeBillingAddress();

		this.getCreditCardsData();

		await this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() });
		if (this.selectedPaymentMethodName === 0) {
			this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
		}
		if (this.paymentMethods.some((method) => method.attributes.method_code_name === 'cashondelivery')) {
			this.walletType = this.walletTypes.commission;
			this.wallets.getBalance();
		}
	},
	methods: {
		getStoredInformation() {
			if (store.getters.getCompletedSteps().includes('PurchasePayment')) {
				const stepPaymentInfo = this.getStepInformation('PurchasePayment').payment;
				// This linting disabler must be removed when the linter is upgraded
				// See: https://github.com/babel/eslint-plugin-babel/pull/163
				// eslint-disable-next-line camelcase
				this.cardId = stepPaymentInfo.payment_method?.card_id || 0;
				// eslint-disable-next-line camelcase
				this.selectedPaymentMethodName = stepPaymentInfo.payment_method?.name || this.creditCardPaymentMethod;
				// eslint-disable-next-line camelcase
				this.walletType = stepPaymentInfo.payment_method?.wallet_type || WALLET_TYPES.commission;
			} else if (!Array.isArray(this.initialInformation.credit_card.card)) {
				if (this.initialInformation.credit_card.card) {
					this.cardId = this.initialInformation.credit_card.card.id;
				}
			}
			// } else {
			// 	this.showMainCancel = false;
			// }
		},
		getCreditCardsData(setCardId = true) {
			return this.creditCards.getCreditCards(this.$user.details().id).then((response) => {
				if (setCardId && response.length) {
					this.cardId = Number(response[0].id);
				} else {
					this.addCreditCard = true;
				}
			});
		},
		newCreditCard() {
			this.$refs.newCreditCardView.newCreditCard();
		},
		cardFormState() {
			this.addCreditCard = !this.addCreditCard;
		},
		async cardCreated(cardId) {
			this.cardId = cardId;
			this.getCreditCardsData(false)
				.then(() => this.prepareForStorage())
				.finally(() => { this.addCreditCard = false; });
		},
		async prepareForStorage() {
			this.preparing = true;

			const paymentMethodName = this.selectedPaymentMethodName;
			const { billingAddressId } = this;

			const payload = {};
			if (CREDIT_CARD_FORM_METHODS.includes(paymentMethodName)) {
				payload.payment = {
					payment_method: {
						name: paymentMethodName,
						card_id: this.cardId,
					},
				};
			} else if (paymentMethodName === 'cashondelivery') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						wallet_password: this.walletPassword,
						wallet_type: this.walletType,
					},
				};
			} else if (paymentMethodName === 'checkmo') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			} else if (paymentMethodName === 'banktransfer') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			} else if (paymentMethodName === 'sezzle') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						// eslint-disable-next-line no-underscore-dangle
						sift_session_id: window._sift_session_id,
					},
				};
			} else if (paymentMethodName === 'oxxopay') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						// eslint-disable-next-line no-underscore-dangle
						sift_session_id: window._sift_session_id,
					},
				};
			} else if (paymentMethodName === 'gcash') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			} else if (paymentMethodName === 'lptpaypal') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						// eslint-disable-next-line no-underscore-dangle
						sift_session_id: window._sift_session_id,
					},
				};
			}

			this.updatePurchase(payload).then(() => {
				if (CREDIT_CARD_FORM_METHODS.includes(payload.payment.payment_method.name)) {
					this.creditCard.getCreditCard(this.$user.details().id, payload.payment.payment_method.card_id).then((response) => {
						const cardAddress = response.attributes.address;
						payload.payment.billing = {
							...payload.payment.billing,
							city: cardAddress.city,
							country_id: cardAddress.country.code,
							firstname: cardAddress.first_name,
							lastname: cardAddress.last_name,
							postcode: cardAddress.postcode,
							region: {
								region: cardAddress.region.name,
								region_code: cardAddress.region.code,
								region_id: cardAddress.region.id,
							},
							street: [cardAddress.address, cardAddress.address2],
						};
						this.saveStep(payload, 'PurchasePayment');
					});
				} else {
					this.saveStep(payload, 'PurchasePayment');
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}
					if (typeof this.validationErrors[`${this.stepValidation}.payment_method.token`] !== 'undefined') {
						this.purchaseCreditCardsErrors.number.errorMessages = this.validationErrors[`${this.stepValidation}.payment_method.token`];
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.purchaseCreditCardsErrors[field] !== 'undefined' && this.purchaseCreditCardsErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
		// Billing Address
		initializeBillingAddress() {
			this.refreshAddressBook().then((response) => {
				if (!this.form.address_id && response.length) {
					this.form.address_id = response[0].id;
				}
			});
		},
		initializeFormValues() {
			this.form = {
				...this.form,
				first_name: this.$user.details().name,
				last_name: this.$user.details().last_name,
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: this.country,
				region_id: '',
			};
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).then(() => this.prepareForStorage()).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.clearValidationError(true);
			this.addAddress = !this.addAddress;
		},
	},
};
</script>
