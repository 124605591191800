<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col d-flex justify-content-center">
				<div class="h2 mt-3">
					{{ translate('purchase_confirmation') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								:compact="isCompact('PurchaseShipping')"
								:init-edit="isEditing('PurchaseShipping')"
								:title="`${translate('shipping_information')}`"
								:hide-controls="isEditing('PurchaseShipping') || loadingInitialInformation"
								active-color="dark"
								hide-cancel
								override-edit
								hide-edit
								:no-inner-spacing="isEditing('PurchaseShipping')"
								@toggled="editStep('PurchaseShipping')">
								<div>
									<p class="m-0">
										<i class="fas fa-shopping-bag mr-2" />
										<b>{{ translate('pickup_title') }}</b>
									</p>
									<p>
										{{ translate('pickup_description_1') }}
										<br>
										{{ translate('pickup_description_2') }}
									</p>
								</div>
							</flow-info-section>
							<flow-info-section
								:compact="isCompact('PurchasePayment')"
								:init-edit="isEditing('PurchasePayment')"
								:hide-controls="isEditing('PurchasePayment') || loadingInitialInformation"
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('PurchasePayment')"
								@toggled="editStep('PurchasePayment')">
								<payment-overview
									v-if="!isEditing('PurchasePayment')"
									:loading-override="loadingInitialInformation"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@noData="editStep('PurchasePayment')" />
								<payment-edit
									v-if="isEditing('PurchasePayment')"
									:payment-method-conditions="paymentMethodConditions"
									@cancel="handleCancelledStep('PurchasePayment')"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('PurchasePayment')"
									@changePaymentMethodSelected="changePaymentMethodSelected" />
							</flow-info-section>
							<flow-info-section
								v-if="cartHasTickets"
								:compact="false"
								init-edit
								hide-controls
								:title="translate('event_attendees')"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="true">
								<div
									v-if="!systemLoading">
									<div
										class="col-12 mt-3">
										<p class="my-0 font-weight-bolder text-danger">
											{{ translate('ticket_not_refundable_disclaimer') }}
										</p>
									</div>
									<event-ticket
										v-for="(item, key) in tickets"
										:key="key"
										:ticket-code-name="ticketCodeName(item.code_name)"
										:names="eventAttendees[ticketCodeName(item.code_name)]"
										:quantity="item.qty"
										:ticket-count="item.tickets_count"
										:companion-types="item.companion_types"
										:bed-types="item.bed_types"
										@dataChanged="saveTickets(ticketCodeName(item.code_name), $event)" />
								</div>
								<div
									v-else
									class="col-12 fade-in text-center d-flex justify-content-center align-items-center mt-3 mb-3">
									<div class="col-12">
										<div class="h2">
											<i class="fa fa-fw fa-spinner fa-pulse" />
											<h4 class="mt-3">
												{{ translate('loading') }}
											</h4>
										</div>
									</div>
								</div>
							</flow-info-section>
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('review_your_cart_items') }}</h5>
							</div>
						</div>
						<div
							v-if="showMessages"
							class="row mt-1">
							<div class="col">
								<possible-discounts
									v-if="!systemLoading"
									:possible-discount-messages="possibleDiscountMessages"
									:free-products-info="freeProductsInfo"
									:promotions="promotions" />
							</div>
						</div>
						<div class="row mt-1">
							<div class="col">
								<cart-items
									:loading="systemLoading || loadingInitialInformation"
									:cart-items="cartProducts"
									:qty-import="qty"
									hide-loading
									@update-quantity="updateQuantity"
									@remove-product="removeProduct"
									@use-points="usePoints"
									@remove-points="removePoints" />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading || loadingInitialInformation"
							:has-data="cartProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:free-products-info="freeProductsInfo"
					:cart-loading="systemLoading || loadingInitialInformation"
					:cart-totals="cartTotals"
					:cart-points="cartPoints"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:has-tickets="cartHasTickets"
					:tickets-info="eventAttendees"
					:total-volume="totalVolume"
					:split-payment-info="splitPaymentInfo"
					:split-payment-loading="splitPaymentLoading"
					:clicked-payment-method="clickedPayment"
					:cart-coupon="cartCoupon"
					:coupon-errors="couponErrors"
					:coupon-loading="couponLoading"
					:remove-coupon-loading="removeCouponLoading"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:terms-and-policies="termsAndPolicies"
					:show-credits="showCredits"
					:credits-used="creditsUsed"
					:apply-free-bottle="applyFreeBottle"
					:free-bottle-message="freeBottleMessage"
					:editing-other-steps="isEditingAnyStep"
					:upgrade-packages="upgradePackages"
					:upgrade-volume="upgradeVolume"
					:has-upgrade-packages="hasUpgradePackages"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					@couponSubmit="applyCoupon"
					@couponRemove="removeCoupon"
					@couponChange="handleCouponChange"
					@cartValidationError="redirectToStore"
					@invalidRequest="handleInvalidRequest"
					@serverError="redirectToStore"
					@purchaseClick="handleOrderClick"
					@purchaseErrors="handlePurchaseErrors"
					@cartReplaced="handleCartReplaced"
					@splitPayment="splitPayment"
					@reloadCart="getCartTotals" />
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import EventTicket from '@/components/EventTicket';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import CartData from '@/mixins/PhysicalStore/CartData';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { affiliate, customer, distributor } from '@/settings/Roles';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations, Events,
} from '@/translations';
import Purchase from '@/util/PhysicalStore/Purchase';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import Steps from './mixins/Steps';
import store from './store';

export default {
	name: 'PurchaseConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations, Events],
	components: {
		PossibleDiscounts,
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentOverview,
		PaymentEdit,
		EventTicket,
	},
	mixins: [CartData, DocumentTitle, Steps, WindowSizes],
	data() {
		return {
			purchaseInfo: new Purchase(),
			distributor,
			canLeave: false,
			editing: {
				PurchaseShipping: false,
				PurchasePayment: false,
				PSPurchaseConfirmation: false,
			},
			purchaseErrors: [],
			clickedPayment: '',
		};
	},
	computed: {
		loadingInitialInformation() {
			return this.purchaseInfo.data.loading;
		},
		isEditingAnyStep() {
			return Object.keys(this.editing)
				.filter((stepName) => stepName !== 'PSPurchaseConfirmation')
				.some((stepName) => this.editing[stepName]);
		},
		hasPurchaseErrors() {
			return !!this.purchaseErrors.length;
		},
		showCredits() {
			return [distributor, affiliate, customer].includes(this.$user.details().type) && this.hasCredits;
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('PurchasePayment') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name || this.clickedPayment;
		},
	},
	watch: {
		cartProducts(newVal) {
			if (!newVal.length) {
				this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
				setTimeout(() => {
					this.redirectToStore();
				}, 6000);
			}
		},
		paymentMethodConditions: {
			deep: true,
			handler() {
				if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
					this.resetPaymentMethod();
				}
			},
		},
		clickedPayment(newVal) {
			if (typeof this.paymentMethodConditions[newVal] !== 'undefined' && !this.paymentMethodConditions[newVal]?.isAvailable) {
				this.editStep('PurchasePayment');
			}
		},
	},
	async mounted() {
		this.setFlowName('purchase');
		await store.dispatch('removeInfo');
		await this.getStartingInfo();
		if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
			await this.resetPaymentMethod();
		}
		if (this.$user.details().type === this.distributor) {
			this.getUpgradePackagesInfo();
		}
	},
	methods: {
		async getStartingInfo() {
			const response = await this.purchaseInfo.getPurchaseInformation();
			await store.dispatch('saveInitialInformation', response.response);
			return this.setCartCookie(response.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.editing[step];
		},
		editStep(step) {
			this.editing[step] = true;
		},
		setStepAsNotEditing(step) {
			this.editing[step] = false;
		},
		isCompact(step) {
			return !this.isEditing(step) && this.stepIsCompleted(step);
		},
		handleCancelledStep(step) {
			this.setStepAsNotEditing(step);
		},
		handleSavedStep(step) {
			this.getCartTotals();
			this.setStepAsNotEditing(step);
		},
		changePaymentMethodSelected(selected) {
			if (selected !== 0) this.clickedPayment = selected;
		},
		handleInvalidRequest() {
			// this.createNewCart();
			// this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			// setTimeout(() => {
			this.resetStepsAndRedirect();
			// }, 6000);
		},
		handlePurchaseErrors(purchaseErrors) {
			this.purchaseErrors = purchaseErrors;
		},
		handleOrderClick() {
			this.purchaseErrors = [];
		},
		handleCartReplaced(cartId) {
			this.setCartCookie(cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` });
		},
		resetStepsAndRedirect() {
			store.dispatch('removeInfo').then(() => {
				this.redirectToStore();
			}).catch(() => {});
		},
		redirectToStore() {
			this.canLeave = true;
			this.$router.replace({ name: 'PSStore' });
		},
		resetPaymentMethod() {
			const paymentInformation = store.getters.getStepInformation('PurchasePayment');
			const data = {
				PurchasePayment: {
					payment: {
						billing: {
							address_id: 0,
						},
					},
				},
			};
			if (paymentInformation.payment) {
				data.PurchasePayment.payment.billing.address_id = paymentInformation.payment.billing.address_id;
			}
			return store.dispatch('saveInfo', data)
				.then(() => store.dispatch('saveStepAsIncomplete', 'PurchasePayment'))
				.then(() => this.editStep('PurchasePayment'));
		},
		ticketCodeName(codeName) {
			if (codeName.endsWith('_mp')) {
				return codeName.slice(0, -3);
			}
			return codeName;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('PSPurchase')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('PSStore')) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		}
	},
};
</script>
