<!-- eslint-disable vue/no-v-html -->
<template>
	<cart-totals
		:class="{ 'outline': !editingOtherSteps }"
		:loading="cartLoading"
		:totals="cartTotals"
		:small-text-totals="cartSmallTextTotals"
		:cart-items="cartItems"
		:total="cartTotal"
		:total-volume="totalVolume"
		:disable-action="!disclaimer || editingOtherSteps || !dataIsComplete || !cartItems.length || loadingInitialInformation || splitPaymentLoading"
		:exchange="exchange"
		action-classes="btn-lg"
		:action-text="selectableFreeProducts.length > 0 ? translate('choose_your_free_products') : ''"
		@action="handleFreeProductsPromotion">
		<template
			v-if="showUpgradeProgress"
			slot="top">
			<div class="row h-100 mb-3">
				<div class="col">
					<div class="card p-0 m-0">
						<div class="card-body p-2 my-1">
							<upgrade-progress
								:packages="upgradePackages"
								:current-volume="upgradeVolume" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<cart-points
			v-if="showCredits"
			:points="cartPoints"
			@use-points="$emit('use-points')"
			@remove-points="$emit('remove-points')"
			@update-split-wallet="$emit('update-split-wallet')" />
		<template v-if="showCredits">
			<div
				v-if="creditsUsed > 0"
				class="row mt-3">
				<div class="col d-flex justify-content-center">
					<div class="alert alert-green text-center h5 w-100 font-weight-normal">
						<span
							v-if="creditsUsed === 1"
							v-html="translate('using_point')" />
						<span
							v-else
							v-html="translate('using_points', { credits: creditsUsed.toLocaleString() })" />
					</div>
				</div>
			</div>
		</template>
		<div
			v-if="(splitPaymentsConfig.allowedPaymentMethods.includes(clickedPaymentMethod) || clickedPaymentMethod === '') && splitPaymentInfo.available"
			class="row no-gutters mb-3">
			<div class="col">
				<split-payment
					:loading="splitPaymentLoading"
					:active="splitPaymentInfo.active"
					@change="$emit('splitPayment', $event)" />
			</div>
		</div>
		<div
			v-if="[distributor, customer, affiliate].includes(this.$user.details().type)"
			class="row no-gutters mb-3">
			<div class="col">
				<coupon-input
					:cart-coupon="cartCoupon"
					:errors-import="couponErrors"
					:loading="couponLoading || removeCouponLoading || cartLoading"
					@submit="$emit('couponSubmit', $event)"
					@remove="$emit('couponRemove')"
					@change="$emit('couponChange', $event)" />
			</div>
		</div>
		<div
			v-if="applyFreeBottle"
			class="row mt-2">
			<div class="col d-flex justify-content-center">
				<div class="alert alert-green w-100 pl-1">
					<ul class="fa-ul mb-0">
						<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate(freeBottleMessage)" /></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<disclaimer
					:checked="disclaimer"
					:terms="termsAndPolicies"
					@change="disclaimer = $event" />
			</div>
		</div>
		<free-products-modal
			:sections-import="selectableFreeProducts"
			:selectable-products.sync="selectableProducts"
			:ok-title="translate('confirm')"
			:cancel-title="translate('go_back')"
			@close="showFreeProductsModal = false"
			@confirm="goSuccess" />
	</cart-totals>
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import CartPoints from '@/components/Cart/CartPoints';
import SplitPayment from '@/components/Cart/SplitPayment';
import CouponInput from '@/components/Cart/CouponInput';
import UpgradeProgress from '@/components/UpgradeProgress';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import { distributor, customer, affiliate } from '@/settings/Roles';
import { CC_PAYMENT_METHOD_BY_COUNTRY, SPLIT_PAYMENTS } from '@/settings/Purchase';
import Disclaimer from './Disclaimer';
import CommonMix from '../../mixins/Common';
import store from '../../store';
import FreeProductsModal from '@/components/FreeProductsModal';
import EventBus from '@/util/eventBus';

export default {
	name: 'OrderSummary',
	components: {
		CartTotals,
		CartPoints,
		SplitPayment,
		CouponInput,
		UpgradeProgress,
		Disclaimer,
		FreeProductsModal,
	},
	mixins: [CommonMix],
	props: {
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
		cartLoading: {
			type: Boolean,
			default: false,
		},
		cartItems: {
			type: Array,
			default: () => [],
		},
		cartTotals: {
			type: Object,
			default: () => ({}),
		},
		cartPoints: {
			type: Object,
			default: () => ({}),
		},
		cartSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		cartTotal: {
			type: String,
			default: '',
		},
		couponErrors: {
			type: Object,
			default: () => ({}),
		},
		termsAndPolicies: {
			type: Object,
			default: () => ({}),
		},
		couponLoading: {
			type: Boolean,
			default: false,
		},
		removeCouponLoading: {
			type: Boolean,
			default: false,
		},
		cartCoupon: {
			type: Object,
			default: () => ({}),
		},
		totalVolume: {
			type: String,
			default: '',
		},
		exchange: {
			type: String,
			default: '',
		},
		showCredits: {
			type: Boolean,
			default: false,
		},
		creditsUsed: {
			type: Number,
			default: 0,
		},
		applyFreeBottle: {
			type: Boolean,
			default: false,
		},
		freeBottleMessage: {
			type: String,
			default: '',
		},
		editingOtherSteps: {
			type: Boolean,
			default: false,
		},
		hasTickets: {
			type: Boolean,
			default: false,
		},
		ticketsInfo: {
			type: Object,
			default: () => ({}),
		},
		upgradePackages: {
			type: Array,
			default: () => [],
		},
		upgradeVolume: {
			type: Number,
			default: 0,
		},
		hasUpgradePackages: {
			type: Boolean,
			default: false,
		},
		splitPaymentInfo: {
			type: Object,
			default: () => ({}),
		},
		splitPaymentLoading: {
			type: Boolean,
			default: false,
		},
		clickedPaymentMethod: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			distributor,
			customer,
			affiliate,
			disclaimer: false,
			idempotencyId: '',
			paymentMethod: {
				name: '',
			},
			splitPaymentsConfig: SPLIT_PAYMENTS,
			showFreeProductsModal: false,
			selectableProducts: {},
		};
	},
	computed: {
		usableSources() {
			const initialInfo = this.initialInformation;

			return {
				initialPaymentInfo: !!initialInfo.credit_card.card && !Array.isArray(initialInfo.credit_card.billing_address),
				storedPaymentInfo: store.state.completedSteps.includes('PurchasePayment'),
			};
		},
		dataIsComplete() {
			return (this.usableSources.initialPaymentInfo || this.usableSources.storedPaymentInfo);
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		showUpgradeProgress() {
			return Number.isInteger(this.upgradeVolume) && this.isDistributor && this.hasUpgradePackages;
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		selectableFreeProducts() {
			try {
				return this.freeProductsInfo.map((info) => info.selectable_products);
			} catch (e) {
				return [];
			}
		},
	},
	watch: {
		currentValidation() {
			this.getStartingInfo();
		},
		completedSteps() {
			this.getStartingInfo();
		},
		showFreeProductsModal(show) {
			EventBus.$emit(show ? 'showFreeProductsModal' : 'hideFreeProductsModal');
		},
	},
	mounted() {
		this.getStartingInfo();
	},
	methods: {
		getStartingInfo() {
			this.idempotencyId = this.createUuid();
			this.stepNames.forEach((stepName) => {
				if (stepName === 'PSPurchaseConfirmation') {
					return null;
				}

				const stepInfo = this.getStepInformation(stepName);
				if (stepName === 'PurchasePayment') {
					// eslint-disable-next-line camelcase
					this.paymentMethod.name = stepInfo.payment?.payment_method?.name ?? '';
				}

				return null;
			});
		},
		handleFreeProductsPromotion() {
			if (this.selectableFreeProducts.length > 0) {
				this.showFreeProductsModal = true;
			} else {
				this.goSuccess();
			}
		},
		goSuccess() {
			this.$emit('purchaseClick');

			let payload = {
				cart_id: this.$user.getCartId(),
				payment: {
					payment_method: {},
				},
				selectable_products: this.selectableProducts,
			};

			if (this.usableSources.initialPaymentInfo) {
				payload.payment.payment_method = {
					name: this.creditCardPaymentMethod,
					card_id: this.initialInformation.credit_card.card.id,
				};
			}

			if (this.hasTickets) {
				payload.tickets = {};
				Object.keys(this.ticketsInfo).forEach((ticketCodeName) => {
					const item = this.cartItems.find((cartItem) => {
						let itemCodeName = cartItem.code_name;
						if (cartItem.code_name.endsWith('_mp')) {
							itemCodeName = itemCodeName.slice(0, -3);
						}
						return itemCodeName === ticketCodeName;
					});

					if (item) {
						payload.tickets[item.sku] = this.ticketsInfo[ticketCodeName];
					}
				});
			}

			const paymentStepInfo = this.getStepInformation('PurchasePayment');
			if (Object.keys(paymentStepInfo).length) {
				const { payment_method: paymentMethod } = paymentStepInfo.payment;
				payload.payment.payment_method = paymentMethod;
			}

			this.stepNames.forEach((stepName) => {
				if (stepName !== 'PSPurchaseConfirmation') {
					payload = { ...payload, ...this.getStepInformation(stepName) };
					if (stepName === 'PurchasePayment') {
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
					}
				}
			});

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			try {
				const validation = { ...payload, step: this.getStepValidationByName('PSPurchaseConfirmation') };
				this.confirmPurchase(validation).then((response) => {
					this.removeInfo().then(() => {
						// Reload user data in case this purchase reactivated their account
						this.$user.init();

						const {
							redirect_url: redirectUrl,
							verification_required: verificationRequired,
							crypto_wallet: cryptoWallet,
							qr_code: qrCode,
						} = response.response;
						// Redirect to instructed page
						if (redirectUrl) {
							window.location.href = redirectUrl;
							return;
						}
						// Pass payment method to success page in case we need to display an alert for it
						const routeOptions = {
							name: 'PSPurchaseSuccess',
							query: { payment_method: payload.payment.payment_method.name ?? '' },
							params: {
								qrCode,
							},
						};
						// Show verbal verification alert
						if (verificationRequired) {
							routeOptions.query.verification_required = true;
						}
						// Go to crypto payment details
						if (cryptoWallet) {
							routeOptions.query = { wallet_address: cryptoWallet };
						}
						// Go to new route
						this.$router.replace(routeOptions);
						// Close 'Processing request...' alert
						this.alert.close();
					});
				}).catch((error) => {
					this.idempotencyId = this.createUuid();
					if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
						this.$emit('invalidRequest', error);
					}
					if (UNPROCESSABLE.includes(error.status)) {
						let isCatchableError = false;
						const {
							cart_id: cartId,
							payex,
							'payment.billing.address_id': billingAddressId,
							'payment.payment_method.name': paymentMethodName,
							'payment.payment_method.wallet_password': walletPassword,
							'payment.payment_method.wallet_type': walletType,
							'payment.payment_method.card_id': cardId,
							tickets,
							selectable_products: selectableProducts,
						} = error.errors;
						if (typeof cartId !== 'undefined') {
							isCatchableError = true;
							if (typeof cartId === 'string' || typeof cartId === 'number') {
								this.$emit('cartReplaced', cartId);
							} else {
								let response = '';
								cartId.forEach((item) => { response += `${item} \n`; });
								this.alert.toast('error', response, { timer: 6000 });
								setTimeout(() => {
									this.$emit('cartValidationError');
								}, 6000);
							}
						}
						if (typeof payex !== 'undefined') {
							isCatchableError = true;
							let response = '';
							payex.forEach((item) => { response += `${item} <br>`; });
							this.alert.error(this.translate('something_went_wrong'), response, true);
						// } else if (typeof tickets !== 'undefined') {
						// 	let response = '';
						// 	tickets.forEach((item) => { response += `${item} <br>`; });
						// 	this.alert.error(this.translate('something_went_wrong'), response, true);
						}
						if (typeof paymentMethodName !== 'undefined') {
							isCatchableError = true;
							let response = '';
							paymentMethodName.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
						}
						if (typeof selectableProducts !== 'undefined') {
							isCatchableError = true;
							let response = '';
							selectableProducts.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
							setTimeout(() => {
								this.$emit('reloadCart');
							}, 6000);
						}
						const errors = [];
						if (typeof billingAddressId !== 'undefined') {
							billingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof walletPassword !== 'undefined') {
							walletPassword.forEach((item) => { errors.push(item); });
						}
						if (typeof walletType !== 'undefined') {
							walletType.forEach((item) => { errors.push(item); });
						}
						if (typeof cardId !== 'undefined') {
							cardId.forEach((item) => { errors.push(item); });
						}
						if (typeof tickets !== 'undefined') {
							tickets.forEach((item) => { errors.push(item); });
						}
						this.$emit('purchaseErrors', errors);

						if (error.length) {
							isCatchableError = true;
							this.alert.close();
						}
						if (isCatchableError === false) {
							this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
						}
					} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
						setTimeout(() => { this.$emit('serverError'); }, 2500);
					}
				});
			} catch (error) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn(error);
				}
			}
			return null;
		},
		selectedPaymentMethod() {
			if (this.paymentMethod.name === '') {
				return this.initialInformation.credit_card?.card ? this.creditCardPaymentMethod : '';
			}
			return this.paymentMethod.name;
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
